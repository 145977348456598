import { FormatPrice } from "../models/cart";

export const TransformaMonto = (amount: number | null) => {
    return (amount !== null && Number(amount.toString().substring(0, amount.toString().length - 2) + '.'
        + amount.toString().substring(amount.toString().length - 2, amount.toString().length)).toFixed(2));
}

export function GetRandomInteger(min: any, max: any): number {
    if (isNaN(min) || isNaN(max)) {
        throw new Error("min and max must be a number");
    }
    min = Number(min);
    max = Number(max);
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
}

export const precioFormat = (id: string, amount: number) : FormatPrice => {
    const precio: FormatPrice = {
      id: null,
      amount: amount,
      currency_code: 'PEN',
      formatted_amount: 'S/.' + TransformaMonto(amount)
    };
    return precio;
}

export const rellenarCero = (number:number, width:number) => {
    var numberOutput = Math.abs(number); /* Valor absoluto del número */
    var length = number.toString().length; /* Largo del número */ 
    var zero = "0"; /* String de cero */  
    
    if (width <= length) {
        if (number < 0) {
             return ("-" + numberOutput.toString()); 
        } else {
             return numberOutput.toString(); 
        }
    } else {
        if (number < 0) {
            return ("-" + (zero.repeat(width - length)) + numberOutput.toString()); 
        } else {
            return ((zero.repeat(width - length)) + numberOutput.toString()); 
        }
    }
}

export const separarFecha = (i:number, response: any) =>{
    const fecha = response.payment.fechaTransaction.split("");        
    return fecha[i];
}  

// Convertir Hex a RGBA con opacidad 9%
export const getRGBAColor = (color: string, opacity = 0.09) => {
    let r: number, g: number, b: number;

    if (color.startsWith("#")) {
      // Convertir color hexadecimal a RGB
      r = parseInt(color.slice(1, 3), 16);
      g = parseInt(color.slice(3, 5), 16);
      b = parseInt(color.slice(5, 7), 16);
    } else if (color.startsWith("rgb")) {
      // Extraer valores RGB de una cadena RGB
      const rgb = color.match(/(\d+),\s*(\d+),\s*(\d+)/);
      if (rgb) {
        r = parseInt(rgb[1], 10);
        g = parseInt(rgb[2], 10);
        b = parseInt(rgb[3], 10);
      } else {
        // Si el formato no es válido, devolver el color original con opacidad
        return `rgba(0, 0, 0, ${opacity})`;
      }
    } else {
      // Si el formato no es válido, devolver el color original con opacidad
      return `rgba(0, 0, 0, ${opacity})`;
    }

    return `rgba(${r}, ${g}, ${b}, ${opacity})`;
};
