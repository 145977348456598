//ALERGENOS
import Almendra from "../assets/Icon/ALERGENOS/almendra_56X56.svg";
import Apio from "../assets/Icon/ALERGENOS/apio_56X56.svg";
import Azucar from "../assets/Icon/ALERGENOS/azucar_56X56.svg";
import Cebolla from "../assets/Icon/ALERGENOS/cebolla_56X56.svg";
import Fresa from "../assets/Icon/ALERGENOS/fresa_56X56.svg";
import Gluten from "../assets/Icon/ALERGENOS/gluten_56X56.svg";
import Hongos from "../assets/Icon/ALERGENOS/hongos_56X56.svg";
import Huevo from "../assets/Icon/ALERGENOS/huevo_56X56.svg";
import Lacteos from "../assets/Icon/ALERGENOS/lacteos_56X56.svg";
import Limon from "../assets/Icon/ALERGENOS/limon_56X56.svg";
import Maiz from "../assets/Icon/ALERGENOS/maiz_56X56.svg";
import Mani from "../assets/Icon/ALERGENOS/mani_56X56.svg";

//lIGHT
import BolsoHover from "../assets/Icon/LIGHT/BOLSA_HOVER_60X60.svg";
import BolsoPasivo from "../assets/Icon/LIGHT/BOLSA_PASIVO_60X60.svg";
import BuscarCerrarHover from "../assets/Icon/LIGHT/BUSCAR_CERRAR_HOVER_48X48.svg";
import BuscarCerrarPasivo from "../assets/Icon/LIGHT/BUSCAR_CERRAR_PASIVO_48X48.svg";
import BuscarHover from "../assets/Icon/LIGHT/BUSCAR_HOVER_48X48.svg";
import BuscarPasivo from "../assets/Icon/LIGHT/BUSCAR_PASIVO_48X48.svg";
import CheckBox from "../assets/Icon/LIGHT/CHECKBOX_WHILE_24X24.svg";
import Check from "../assets/Icon/LIGHT/CHECK_24X24.svg";
import CompartirPasivo from "../assets/Icon/LIGHT/COMPARTIR_PASIVO_24X24.svg";
import Estrella from "../assets/Icon/LIGHT/ESTRELLA_24X24.svg";
import Favorito from "../assets/Icon/LIGHT/FAVORITOS_HOVER_60X60.svg";
import FavoritoMas from "../assets/Icon/LIGHT/FAVORITO_MAS_24X24.svg";
import FavoritoMasVerde from "../assets/Icon/LIGHT/FAVORITO_MAS_VERDE_24X24.svg";
import FavoritoVerde from "../assets/Icon/LIGHT/FAVORITO_VERDE_24X24.svg";
import Filtro from "../assets/Icon/LIGHT/FILTRO_BOTON_GREEN_24X24.svg";
import FiltroHover from "../assets/Icon/LIGHT/FILTRO_HOVER_48X48.svg";
import FlechaAbajoHover from "../assets/Icon/LIGHT/FLECHA_ABAJO_HOVER_24X24.svg";
import FlechaAbajoPasivo from "../assets/Icon/LIGHT/FLECHA_ABAJO_PASIVO_24X24.svg";
import FlechaArribaHover from "../assets/Icon/LIGHT/FLECHA_ARRIBA_HOVER_24X24.svg";
import FlechaArribaPasivo from "../assets/Icon/LIGHT/FLECHA_ARRIBA_PASIVO_24X24.svg";
import FlechaDerechaHover from "../assets/Icon/LIGHT/FLECHA_DERECHA_HOVER_24X24.svg";
import FlechaDerechaPasivo from "../assets/Icon/LIGHT/FLECHA_DERECHA_PASIVO_24X24.svg";
import FlechaIzquierdaHover from "../assets/Icon/LIGHT/FLECHA_IZQUIERDA_HOVER_24X24.svg";
import FlechaIzquierdaPasivo from "../assets/Icon/LIGHT/FLECHA_IZQUIERDA_PASIVO_24X24.svg";
import LineaDrawer from "../assets/Icon/LIGHT/LINEA_DRAWER.svg";
import LogoCerrarActivo from "../assets/Icon/LIGHT/LOGO_CERRAR_ACTIVO_24X24.svg";
import LunaHover from "../assets/Icon/LIGHT/LUNA_HOVER_24X24.svg";
import LunaPasivo from "../assets/Icon/LIGHT/LUNA_PASIVO_24X24.svg";
import Puntos from "../assets/Icon/LIGHT/PUNTOS_PASIVO_24x24.svg";
import UbicacionHover from "../assets/Icon/LIGHT/UBICACION_HOVER_20X20.svg";
import UbicacionPasivo from "../assets/Icon/LIGHT/UBICACION_PASIVO_20X20.svg";
import FlechaRedondaIzquierda from "../assets/Icon/LIGHT/FLECHA_IZQUIERDA_PASIVO_32X32.svg";
import CheckCircularPasivo from "../assets/Icon/LIGHT/CHECK_CIRCULAR_PASIVO_24X24.svg";
import ErrorCircular from "../assets/Icon/LIGHT/ERROR_CIRCULAR_20X20.svg";
import MasCarrito from "../assets/Icon/LIGHT/MAS_CARRITO_24X24.svg";
import MenosCarrito from "../assets/Icon/LIGHT/MENOS_CARRITO_24X24.svg";
import MenuPasivo from "../assets/Icon/LIGHT/MENU_PASIVO_24X24.svg";
import TiendaPasivo from "../assets/Icon/LIGHT/TIENDA_PASIVO_100X100.svg";
import TiendaHover from "../assets/Icon/LIGHT/TIENDA_HOVER_100X100.svg";

//Dark
import BolsoHoverDark from "../assets/Icon/DARK/BOLSA_HOVER_60X60.svg";
import BolsoPasivoDark from "../assets/Icon/DARK/BOLSA_PASIVO_60X60.svg";
import BuscarCerrarHoverDark from "../assets/Icon/DARK/BUSCAR_CERRAR_HOVER_48X48.svg";
import BuscarCerrarPasivoDark from "../assets/Icon/DARK/BUSCAR_CERRAR_PASIVO_48X48.svg";
import BuscarHoverDark from "../assets/Icon/DARK/BUSCAR_HOVER_48X48.svg";
import BuscarPasivoDark from "../assets/Icon/DARK/BUSCAR_PASIVO_48X48.svg";
import CheckBoxDark from "../assets/Icon/DARK/CHECKBOX_WHILE_24X24.svg";
import CheckDark from "../assets/Icon/DARK/CHECK_24X24.svg";
import CompartirPasivoDark from "../assets/Icon/DARK/COMPARTIR_PASIVO_24X24.svg";
import EstrellaDark from "../assets/Icon/DARK/ESTRELLA_24X24.svg";
import FavoritoDark from "../assets/Icon/DARK/FAVORITOS_HOVER_60X60.svg";
import FavoritoMasDark from "../assets/Icon/DARK/FAVORITO_MAS_24X24.svg";
import FavoritoMasVerdeDark from "../assets/Icon/DARK/FAVORITO_MAS_VERDE_24X24.svg";
import FavoritoVerdeDark from "../assets/Icon/DARK/FAVORITO_VERDE_24X24.svg";
import FiltroDark from "../assets/Icon/DARK/FILTRO_BOTON_GREEN_24X24.svg";
import FiltroHoverDark from "../assets/Icon/DARK/FILTRO_HOVER_48X48.svg";
import FlechaAbajoHoverDark from "../assets/Icon/DARK/FLECHA_ABAJO_HOVER_24X24.svg";
import FlechaAbajoPasivoDark from "../assets/Icon/DARK/FLECHA_ABAJO_PASIVO_24X24.svg";
import FlechaArribaHoverDark from "../assets/Icon/DARK/FLECHA_ARRIBA_HOVER_24X24.svg";
import FlechaArribaPasivoDark from "../assets/Icon/DARK/FLECHA_ARRIBA_PASIVO_24X24.svg";
import FlechaDerechaHoverDark from "../assets/Icon/DARK/FLECHA_DERECHA_HOVER_24X24.svg";
import FlechaDerechaPasivoDark from "../assets/Icon/DARK/FLECHA_DERECHA_PASIVO_24X24.svg";
import FlechaIzquierdaHoverDark from "../assets/Icon/DARK/FLECHA_IZQUIERDA_HOVER_24X24.svg";
import FlechaIzquierdaPasivoDark from "../assets/Icon/DARK/FLECHA_IZQUIERDA_PASIVO_24X24.svg";
import LineaDrawerDark from "../assets/Icon/DARK/LINEA_DRAWER.svg";
import LogoCerrarActivoDark from "../assets/Icon/DARK/LOGO_CERRAR_ACTIVO_24X24.svg";
import LunaHoverDark from "../assets/Icon/DARK/LUNA_HOVER_24X24.svg";
import LunaPasivoDark from "../assets/Icon/DARK/LUNA_PASIVO_24X24.svg";
import PuntosDark from "../assets/Icon/DARK/PUNTOS_PASIVO_24x24.svg";
import UbicacionHoverDark from "../assets/Icon/DARK/UBICACION_HOVER_20X20.svg";
import UbicacionPasivoDark from "../assets/Icon/DARK/UBICACION_PASIVO_20X20.svg";
import FlechaRedondaIzquierdaDark from "../assets/Icon/DARK/FLECHA_IZQUIERDA_PASIVO_32X32.svg";
import CheckCircularPasivoDark from "../assets/Icon/DARK/CHECK_CIRCULAR_PASIVO_24X24.svg";
import MasCarritoDark from "../assets/Icon/DARK/MAS_CARRITO_24X24.svg";
import MenosCarritoDark from "../assets/Icon/DARK/MENOS_CARRITO_24X24.svg";
import MenuPasivoDark from "../assets/Icon/DARK/MENU_PASIVO_24X24.svg";
import TiendaPasivoDark from "../assets/Icon/DARK/TIENDA_PASIVO_100X100.svg";
import TiendaHoverDark from "../assets/Icon/DARK/TIENDA_HOVER_100X100.svg";

//LOGIN
import Alertas from "../assets/Icon/LOGIN/ALERTA_24X24.svg";
import ContrasenaActivo from "../assets/Icon/LOGIN/CONTRASENA_ACTIVO_24X24.svg";
import ContrasenaPasivo from "../assets/Icon/LOGIN/CONTRASENA_PASIVO_24X24.svg";
import OjoMostar from "../assets/Icon/LOGIN/OJO_MOSTRAR_24X24.svg";
import OjoOcultar from "../assets/Icon/LOGIN/OJO_OCULTAR_24X24.svg";
import UsuarioActivo from "../assets/Icon/LOGIN/USUARIO_ACTIVO_24X24.svg";
import UsuarioPasivo from "../assets/Icon/LOGIN/USUARIO_PASIVO_24X24.svg";

//PAISES
import Espanol from "../assets/Icon/PAISES/ESPAÑOL_24X24.svg";
import Ingles from "../assets/Icon/PAISES/INGLES_24X24.svg";

//TARGETAS
import AmericanExpress from "../assets/Targetas/AMERICAN_EXPRESS_48X48.svg";
import DinersClub from "../assets/Targetas/DINERS_CLUB_48X48.svg";
import Mastercard from "../assets/Targetas/MASTERCARD_48X48.svg";
import visa from "../assets/Targetas/VISA_48X48.svg";

//LOTTIES
import AlertaLotties from "../assets/Lotties/ALERTA_320X320.json";
import CargaVerdeLotties from "../assets/Lotties/CARGA_VERDE_200X200.json";
import CargaVerdePuntoLotties from "../assets/Lotties/CARGA_VERDE_PUNTITO.json";
import ErrorLotties from "../assets/Lotties/ERROR_320X320.json";
import ExitoLotties from "../assets/Lotties/EXITO_600X600.json";
import PosLotties from "../assets/Lotties/POS_640X640.json";
import SonrisaLotties from "../assets/Lotties/SONRISA_VERDE_400X400.json";

//LOGOS
import CarritoLogo from "../assets/Logos/LOGO_CARRITO_160X160.svg";
import FavoritoLogo from "../assets/Logos/LOGO_FAVORITOS_240X240.svg";
import MesaLogo from "../assets/Logos/LOGO_MESA_COMERCIO_40X40.svg";
import PagosLogo from "../assets/Logos/LOGO_PAGOS.svg";
import PedidosLogo from "../assets/Logos/LOGO_PEDIDO_240X240.svg";
import PowerdBy from "../assets/Logos/LOGO_POWERD_BY_132X28.svg";
import Logo from "../assets/Logos/LOGO_STOCK_52X52.svg";

//IMAGENES
import DesktopCartaDigital from "../assets/Images/LANDING_CARTA_DIGITAL_DESKTOP_1920X1080.svg";
import MobileCartaDigital from "../assets/Images/LANDING_CARTA_DIGITAL_MOBILE_360X800.svg";
import TableCartaDigital from "../assets/Images/LANDING_CARTA_DIGITAL_TABLET_720x1200.svg";
import Causa from "../assets/Images/Causa-de-Pollo-1.webp";
import Carne from "../assets/Images/carne_con_papas_1.webp";
import pollo from "../assets/Images/close_up_of_peruvian_chicken_causa_1.webp";

// Definir iconos, colores, lotties, logos y tarjetas
const lightIcons = {
  bolsoHover: BolsoHover,
  bolsoPasivo: BolsoPasivo,
  buscarCerrarHover: BuscarCerrarHover,
  buscarCerrarPasivo: BuscarCerrarPasivo,
  buscarHover: BuscarHover,
  buscarPasivo: BuscarPasivo,
  checkBox: CheckBox,
  check: Check,
  compartirPasivo: CompartirPasivo,
  estrella: Estrella,
  favorito: Favorito,
  favoritoMas: FavoritoMas,
  favoritoMasVerde: FavoritoMasVerde,
  favoritoVerde: FavoritoVerde,
  filtro: Filtro,
  filtroHover: FiltroHover,
  flechaAbajoHover: FlechaAbajoHover,
  flechaAbajoPasivo: FlechaAbajoPasivo,
  flechaArribaHover: FlechaArribaHover,
  flechaArribaPasivo: FlechaArribaPasivo,
  flechaDerechaHover: FlechaDerechaHover,
  flechaDerechaPasivo: FlechaDerechaPasivo,
  flechaIzquierdaHover: FlechaIzquierdaHover,
  flechaIzquierdaPasivo: FlechaIzquierdaPasivo,
  lineaDrawer: LineaDrawer,
  logoCerrarActivo: LogoCerrarActivo,
  lunaHover: LunaHover,
  lunaPasivo: LunaPasivo,
  puntos: Puntos,
  ubicacionHover: UbicacionHover,
  ubicacionPasivo: UbicacionPasivo,
  flechaRedondaIzquierda: FlechaRedondaIzquierda,
  checkCircularPasivo: CheckCircularPasivo,
  errorCircular: ErrorCircular,
  mas: MasCarrito,
  menos: MenosCarrito,
  menu: MenuPasivo,
  tienda: TiendaPasivo,

  //LOGIN
  alertas: Alertas,
  contrasenaActivo: ContrasenaActivo,
  contrasenaPasivo: ContrasenaPasivo,
  ojoMostar: OjoMostar,
  ojoOcultar: OjoOcultar,
  usuarioActivo: UsuarioActivo,
  usuarioPasivo: UsuarioPasivo,
  //AlERGENOS
  almendra: Almendra,
  apio: Apio,
  azucar: Azucar,
  cebolla: Cebolla,
  fresa: Fresa,
  gluten: Gluten,
  hongos: Hongos,
  huevo: Huevo,
  lacteos: Lacteos,
  limon: Limon,
  maiz: Maiz,
  mani: Mani,
};

const darkIcons = {
  bolsoHover: BolsoHoverDark,
  bolsoPasivo: BolsoPasivoDark,
  buscarCerrarHover: BuscarCerrarHoverDark,
  buscarCerrarPasivo: BuscarCerrarPasivoDark,
  buscarHover: BuscarHoverDark,
  buscarPasivo: BuscarPasivoDark,
  checkBox: CheckBoxDark,
  check: CheckDark,
  compartirPasivo: CompartirPasivoDark,
  estrella: EstrellaDark,
  favorito: FavoritoDark,
  favoritoMas: FavoritoMasDark,
  favoritoMasVerde: FavoritoMasVerdeDark,
  favoritoVerde: FavoritoVerdeDark,
  filtro: FiltroDark,
  filtroHover: FiltroHoverDark,
  flechaAbajoHover: FlechaAbajoHoverDark,
  flechaAbajoPasivo: FlechaAbajoPasivoDark,
  flechaArribaHover: FlechaArribaHoverDark,
  flechaArribaPasivo: FlechaArribaPasivoDark,
  flechaDerechaHover: FlechaDerechaHoverDark,
  flechaDerechaPasivo: FlechaDerechaPasivoDark,
  flechaIzquierdaHover: FlechaIzquierdaHoverDark,
  flechaIzquierdaPasivo: FlechaIzquierdaPasivoDark,
  lineaDrawer: LineaDrawerDark,
  logoCerrarActivo: LogoCerrarActivoDark,
  lunaHover: LunaHoverDark,
  lunaPasivo: LunaPasivoDark,
  puntos: PuntosDark,
  ubicacionHover: UbicacionHoverDark,
  ubicacionPasivo: UbicacionPasivoDark,
  flechaRedondaIzquierda: FlechaRedondaIzquierdaDark,
  checkCircularPasivo: CheckCircularPasivoDark,
  errorCircular: ErrorCircular,
  mas: MasCarritoDark,
  menos: MenosCarritoDark,
  menu: MenuPasivoDark,
  tienda: TiendaPasivoDark,

  //LOGIN
  alertas: Alertas,
  contrasenaActivo: ContrasenaActivo,
  contrasenaPasivo: ContrasenaPasivo,
  ojoMostar: OjoMostar,
  ojoOcultar: OjoOcultar,
  usuarioActivo: UsuarioActivo,
  usuarioPasivo: UsuarioPasivo,
  //AlERGENOS
  almendra: Almendra,
  apio: Apio,
  azucar: Azucar,
  cebolla: Cebolla,
  fresa: Fresa,
  gluten: Gluten,
  hongos: Hongos,
  huevo: Huevo,
  lacteos: Lacteos,
  limon: Limon,
  maiz: Maiz,
  mani: Mani,
};

const lightColors = {
  Contraste: "#3AAC6E",
  Primarios: "#42C67E",
  Secundarios: "#79DD8B",
  Terciario: "#6CD39B",
  Líneas: "#A6E5C3",
  Pastel: "#EAFAF1",
  Fondos: "#F2FBF7",
  Inactivos: "#98ACB3",
  Fondo: "#FFFFFF",
  BotonFondo: "#212831",

  //Alertas
  TextoErrorHover: "#F44336",
  BotonErroriconos: "#FF655A",
  FondosHover: "#FFECEB",
  TextosAlerta: "#F09713",
  BotónAlertaIconos: "#FCA422",
  Alerta: "#FFF5F0",
};

const darkColors = {
  Contraste: "#3AAC6E",
  Primarios: "#6CD39B",
  Secundarios: "#79DD8B",
  Terciario: "#6CD39B",
  Líneas: "#A6E5C3",
  Pastel: "#EAFAF1",
  Fondos: "#F2FBF7",
  //Fondos:"rgba(26, 29, 34, 0.95)",
  Inactivos: "#98ACB3",
  //Fondo: "#121212",
  Fondo: "#1A1D22",
  BotonFondo: "#212831",

  //Alertas
  TextoErrorHover: "#F44336",
  BotonErroriconos: "#FF655A",
  FondosHover: "#FFECEB",
  TextosAlerta: "#F09713",
  BotónAlertaIconos: "#FCA422",
  Alerta: "#FFF5F0",
};

const LottiesIcons = {
  alertas: AlertaLotties,
  cargaVerde: CargaVerdeLotties,
  error: ErrorLotties,
  pos: PosLotties,
  exito: ExitoLotties,
  Punto: CargaVerdePuntoLotties,
  sonrisa: SonrisaLotties,
};

const LogoIcons = {
  Espanol: Espanol,
  Ingles: Ingles,
  carrito: CarritoLogo,
  favorito: FavoritoLogo,
  mesa: MesaLogo,
  pagos: PagosLogo,
  pedidos: PedidosLogo,
  powerdBy: PowerdBy,
  logo: Logo,
};

const Imagen = {
  Desktop: DesktopCartaDigital,
  Mobile: MobileCartaDigital,
  Table: TableCartaDigital,
  causa: Causa,
  carne: Carne,
  pollo: pollo,
};

const Targetas = {
  AmericanExpress: AmericanExpress,
  DinersClub: DinersClub,
  Mastercard: Mastercard,
  visa: visa,
};

// Exportar temas
export const themes = {
  light: {
    icons: lightIcons,
    colors: lightColors,
    Lotties: LottiesIcons,
    logo: LogoIcons,
    targeta: Targetas,
    imagen: Imagen,
  },
  dark: {
    icons: darkIcons,
    colors: darkColors,
    Lotties: LottiesIcons,
    logo: LogoIcons,
    targeta: Targetas,
    imagen: Imagen,
  },
};
