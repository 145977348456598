import { createTheme, Theme } from "@mui/material/styles";

const getCssVariableValue = (variable: string): string => {
  return getComputedStyle(document.documentElement).getPropertyValue(variable).trim();
};

const getTheme = (darkMode: boolean, ThemesColors: { activo: string; pasivo: string }): Theme => {
  const primaryMainColor = darkMode
    ? getCssVariableValue("--boton-800-primario") || ThemesColors.activo// Usa el color activo
    : ThemesColors.activo; // Color principal para modo claro

  const secondaryMainColor = darkMode
    ? getCssVariableValue("--boton-800-primario") || ThemesColors.activo // Usa el color pasivo
    : ThemesColors.activo; // Color secundario para modo claro

  return createTheme({
    palette: {
      mode: darkMode ? "dark" : "light",
      background: {
        default: darkMode ? "#121212" : "#FFFFFF", // Cambiar color de fondo
        paper: darkMode ? "#121212" : "#FFFFFF", // Cambiar color de modales
      },
      text: {
        primary: darkMode ? "#E7EFFC" : "#1E2947",
        secondary: darkMode ? "#A6E5C3" : "#3CB473",
      },
      primary: {
        main: primaryMainColor, // Color principal dinámico
      },
      secondary: {
        main: secondaryMainColor, // Color secundario dinámico
      },
    },
    typography: {
      fontFamily: ["GothamPro", "Poppins", "sans-serif"].join(","),
    },
  });
};

export default getTheme;
