import React, { lazy, Suspense, useContext, useMemo, useState } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import LoaderScreen from "../components/loaders/LoaderScreen";
import ProtectedRoute from "../authenticate/protectedRoutes";
import PublicRoute from "../authenticate/publicRoutes";
import { ThemeProvider, CssBaseline } from "@mui/material";
import getTheme from "../config/theme";
import SelectionObsionSection from "../pages/MainScreen/components/SelectionObsionSection";
import { UserContext } from "../context/UserProvider";

const CartScreen = lazy(() => import("../pages/CartScreen"));
const CheckoutScreen = lazy(  () => import("../pages/CheckoutScreen/CheckoutScreen"));
const MainScreen = lazy(() => import("../pages/MainScreen/MainScreen"));
const LandingScreen = lazy(() => import("../pages/LandingScreen"));

const Index = () => {
  const [darkMode, setDarkMode] = useState(false);
  const { ThemesColors, isGourmet } = useContext(UserContext);

  const theme = useMemo(
    () => getTheme(darkMode, ThemesColors),
    [darkMode, ThemesColors]
  );

  //SOLO ACTIVAR EL MODO DARK SI ES NECESARIO
  const handleThemeChange = () => {
    setDarkMode((prevMode) => !prevMode);
  };

  return (
    <Suspense fallback={<></>}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <BrowserRouter>
          <Routes>
            {/* Public rutes */}
            {/* <Route path="status/404" element={<Status404 />} />           
              <Route path="*" element={<Status404 />} /> */}
            <Route path="/" element={<LandingScreen isDarkMode={darkMode}/>} />

            {/* ROUTE DEL MAIN (PUBLICAS) */}
            <Route path="/:idStoreRoute/:idMesaRoute" element={<MainScreen isDarkMode={darkMode} />} />
            {/* ROUTE DEL MAIN (PRIVADAS) */}
            <Route  path="/listapedido/:idStoreRoute/:idMesaRoute" element={<CartScreen isDarkMode={darkMode} handleThemeChange={handleThemeChange} />}/> 
            <Route path="/checkout/:idStoreRoute/:idMesaRoute"  element={<CheckoutScreen isDarkMode={darkMode} />} />
          </Routes>
        </BrowserRouter>
      </ThemeProvider>
    </Suspense>
  );
};

export default Index;
